<template>
   <div>
      <el-date-picker v-model="rport.from"
                      type="date"
                      value-format="yyyy-MM-dd"
                      placeholder="选择开始日期">
      </el-date-picker>
      <el-date-picker v-model="rport.to"
                      type="date"
                      value-format="yyyy-MM-dd"
                      placeholder="选择结束日期">
      </el-date-picker>
      <el-input placeholder="请输入姓名或报告类型"
                suffix-icon="el-icon-search"
                v-model="rport.findKey">
      </el-input>
      <el-button @click="search"
                 type="primary">查询</el-button>
      <el-button @click="fromCommit"
                 type="danger">选择数据生成报告</el-button>
      <div>
         <el-table :data="reverseSum"
                   border
                   style="width: 100%"
                   @sort-change="onSortChange"
                   @selection-change="selectionLineChangeHandle">
            <el-table-column type="selection"
                             width="55"> </el-table-column>
            <el-table-column prop="cname"
                             label="患者姓名"
                             align="center"
                             width="150">
            </el-table-column>
            <el-table-column prop="rtName"
                             label="方案名称"
                             align="center"
                             width="150">
            </el-table-column>
            <el-table-column prop="dateTime"
                             label="报告时间"
                             align="center"
                             width="250"
                             sortable="custom"
                             :sort-orders="['ascending', 'descending']">
               <template slot-scope="scope">{{
            scope.row.dateTime | formatDate
          }}</template>
            </el-table-column>

            <el-table-column label="报告结果"
                             prop="solution"
                             align="center"
                             width="220">
            </el-table-column>
            <el-table-column label="方案时间"
                             align="center"
                             width="150"
                             prop="data">
               <template slot-scope="scope">{{
            scope.row.data.split(",")[0]
          }}</template>
            </el-table-column>
            <el-table-column label="光照强度"
                             align="center"
                             width="150"
                             prop="data">
               <template slot-scope="scope">{{
            scope.row.data.split(",")[1]
          }}</template>
            </el-table-column>
            <el-table-column label="身份证号"
                             align="center"
                             width="220"><template slot-scope="scope">{{
            scope.row.cid | hideMiddle
          }}</template>
            </el-table-column>
            <el-table-column fixed="right"
                             label="操作"
                             width="300"
                             align="center">
               <template slot-scope="scope">
                  <el-button type="primary"
                             size="mini"
                             icon="el-icon-view"
                             @click="
                toReport(
                  scope.row.cid,
                  scope.row.id,
                  scope.row.etId,
                  scope.row.cname
                )
              ">查看</el-button>
                  <el-button @click="delBnt(scope.row.id)"
                             size="mini"
                             type="danger"
                             icon="el-icon-circle-close">删除</el-button>
               </template>
            </el-table-column>
         </el-table>
      </div>
   </div>
</template>

<script>
import { formatDate } from "../common/date"; // 在组件中引用date.js
// import { Message } from "element-ui";
// import Page from "./Page.vue";
import { createNamespacedHelpers } from "vuex";
import axios from "../http/axios";
// import api from "../http/api";
const { mapState, mapActions } = createNamespacedHelpers(
   "patient"
);
export default {
   data () {
      return {
         times: [],
         strength: "",
         findKey: "",
         value1: "",
         value2: "",
         searchData: [],
         dialogVisible: false,
         update: {},
         dataonLineListSelections: [],
      };
   },
   mounted () {
      this.rport
   },
   updated () { this.rport },
   created () { this.search() },
   filters: {
      hideMiddle (val) {
         return `${val.substring(0, 3)}****${val.substring(val.length - 3)}`;
      },
      formatDate (time) {
         let date = new Date(time);
         return formatDate(date, "yyyy-MM-dd-hh:mm:ss");
      },
   },
   computed: {
      ...mapState(["VRRport", "rport"]),
      reverseSum () {
         return this.VRRport.reverse();
      },
   },
   methods: {
      ...mapActions(["getReoprtALl", "delVRone"]),
      // 通过关键字查询患者
      async search () {
         // const data = await axios({
         //    url: `/Report/find?from=${this.value1}&to=${this.value2}&findKey=${this.findKey}  `,
         //    method: "get",
         // });
         // if (data.data.msg == "请求处理正常") {
         //    this.searchData = data.data.data;
         // }
         this.getReoprtALl()

      },
      /**
       * 表格排序事件处理函数
       * @param {object} {column,prop,order} 列数据|排序字段|排序方式
       */
      onSortChange ({ prop, order }) {
         this.searchData.sort(this.compare(prop, order));
      },

      /**
       * 排序比较
       * @param {string} propertyName 排序的属性名
       * @param {string} sort ascending(升序)/descending(降序)
       * @return {function}
       */
      compare (propertyName, sort) {
         return function (obj1, obj2) {
            var value1 = obj1[propertyName];
            var value2 = obj2[propertyName];
            if (typeof value1 === "string" && typeof value2 === "string") {
               const res = value1.localeCompare(value2, "zh");
               return sort === "ascending" ? res : -res;
            } else {
               if (value1 <= value2) {
                  return sort === "ascending" ? -1 : 1;
               } else if (value1 > value2) {
                  return sort === "ascending" ? 1 : -1;
               }
            }
         };
      },
      // 选择数据
      selectionLineChangeHandle (val) {
         this.dataonLineListSelections = val;
      },
      //提交
      fromCommit () {
         let arr = this.dataonLineListSelections;
         localStorage.setItem("arrdata", JSON.stringify(arr));
         this.$router.push("/Treatmentreport");
      },
      toReport (id, cid, etId) {
         this.$router.push(`/OneTreatmentreport/${id}/${cid}`);
      },

      // 删除患者
      delBnt (id) {
         this.getReoprtALl()
         // 删除提示框
         this.$confirm("此操作将永久删除该数据，是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "danger",
         })
            .then(() => {
               this.$message({
                  type: "success",
                  message: "删除成功！",
               });
               this.delVRone(id);
               this.getReoprtALl()
            })
            .catch(() => {
               this.$message({
                  type: "info",
                  message: "已取消删除",
               });
            });
      },
   },
};
</script>

<style lang="scss" scoped>
.el-input {
   width: 200px;
}
</style>
